import { useLocation } from "react-router-dom";
import UseFetch from "../components/UseFetch";
import { useEffect, useState } from "react";
import EditePaieFacture from "../Ventes/EditePaieFacture";
import { decryptData } from "../components/const";
import EditePaieDette from "./EditePaieDette";
import EditeAvance from "./EditeAvance";


const CompteClient = () => {
    const location = useLocation();
    const { client } = location.state
    const { data: compteClient, isloading } = UseFetch(`client/${client.id}/compte`);
    const agent = compteClient?.client?.agent;
    let factures = compteClient?.factures?.sort((a, b) => (b.reste - a.reste));
    let paiements = compteClient?.paiements?.sort((a, b) => (b.id - a.id));
    let dettes = compteClient?.dettes;
    let remboursements = compteClient?.remboursements?.sort((a, b) => (b.id - a.id));
    let solde = compteClient?.solde;
    let versements = compteClient?.versements?.sort((a, b) => (b.id - a.id));
    const [index, setIndex] = useState(1);
    // calcul total factures
    const [totalmontant, setTotalMontant] = useState(0);
    const [totalRemise, setTotalRemise] = useState(0);
    const [totalNet, setTotalNet] = useState(0);
    const [totalPaye, setTotalPaye] = useState(0);
    const [totalReste, setTotalReste] = useState(0);
    // calcul total dettes
    const [totalDette, setTotalDettes] = useState(0);
    const [totalDettePaye, setDettePaye] = useState(0);
    const [totalDetteReste, setDetteReste] = useState(0);
    // calcul total solde et avances
    const [totalAvance, setTotalAvance] = useState(0);
    const [selectPaie, setSelectPaie] = useState(null)



    useEffect(() => {
        setTotalMontant(factures?.reduce((previous, current) => { return previous + current.total }, 0));
        setTotalRemise(factures?.reduce((previous, current) => { return previous + current.remise }, 0));
        setTotalNet(factures?.reduce((previous, current) => { return previous + current.montant }, 0));
        setTotalPaye(factures?.reduce((previous, current) => { return previous + current.paye }, 0));
        setTotalReste(factures?.reduce((previous, current) => { return previous + current.reste }, 0));
    }, [factures])
    useEffect(() => {
        if (dettes?.length > 0) {
            setTotalDettes(dettes?.reduce((previous, current) => { return previous + current.dette }, 0));
            setDettePaye(dettes?.reduce((previous, current) => { return previous + current.paye }, 0));
            setDetteReste(dettes?.reduce((previous, current) => { return previous + current.reste }, 0));
        }
    }, [dettes]);
    useEffect(() => {
        setTotalAvance(versements?.reduce((previous, current) => { return previous + current.montant }, 0));
    }, [versements]);
    const user = decryptData("USER");
    return (
        <div className="compte">
            <div className="modal-footer p-0 justify-content-start align-items-center border-bottom pb-2 shadow mb-2 rounded">
                <button className="btn btn-primary mx-1" onClick={() => setIndex(1)}>Factures</button>
                <button className="btn btn-primary mx-1" onClick={() => setIndex(2)}>Dettes initiales</button>
                <button className="btn btn-primary mx-1" onClick={() => setIndex(3)}>Comptes et avances</button>
            </div>
            {
                isloading ?
                    <div className="loader"></div> :
                    <div className="row">
                        <div className="row my-3">
                            <h4 className="text-primary m-2"> DETAIL COMPTE CLIENT </h4>
                            <hr />
                            <div className="col-lg-6">
                                <div>  <span className="fw-bold mx-2">Client</span>: {client?.client?.toUpperCase()}</div>
                                <div><span className="fw-bold mx-2">Telephone</span>: {client?.telephone?.toUpperCase()}</div>
                                <div><span className="fw-bold mx-2">Credit max</span>: {client?.dettemax?.toLocaleString()} GNF</div>
                                <div><span className="fw-bold mx-2">Solde Actuel</span>: {solde?.toLocaleString()} GNF</div>
                            </div>
                            <div className="col-lg-6">
                                <div>  <span className="fw-bold mx-2">Agent responsable</span>: {`${agent?.prenom} ${agent?.nom}`}</div>
                                <div><span className="fw-bold mx-2">Telephone</span>: {agent?.telephone?.toUpperCase()}</div>
                            </div>
                        </div>
                        {index === 1 && <div className="factures">
                            {
                                factures?.length > 0 ?
                                    <div className="container mt-2-fluid">
                                        <h4>LISTE DES FACTURES</h4>
                                        <div className="table-responsive mt-2">
                                            <table className="table table-striped table-hover table-bordered">
                                                <thead className="table-primary">
                                                    <tr>
                                                        <th>N°</th>
                                                        <th>N°FACTURE</th>
                                                        <th>MONTANT</th>
                                                        <th>REMISE</th>
                                                        <th>MONTANT NET</th>
                                                        <th>PAYE</th>
                                                        <th>RESTE</th>
                                                        <th>VENDEUR</th>
                                                        <th>MAGASIN</th>
                                                        <th>DATE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        factures.map((fact, ind) => (
                                                            <tr key={ind} className={`align-middle ${fact?.suppression ? "bg-danger-subtle" : ""}`}>
                                                                <td>{ind + 1}</td>
                                                                <td>{fact.numero}</td>
                                                                <td>{fact.total.toLocaleString()}</td>
                                                                <td>{(+fact.remise).toLocaleString()}</td>
                                                                <td>{(+fact.montant).toLocaleString()}</td>
                                                                <td>{(+fact.paye).toLocaleString()}</td>
                                                                <td>{(+fact.reste).toLocaleString()}</td>
                                                                <td>{(fact.vendeur)?.toUpperCase()}</td>
                                                                <td>{(fact.magasin).toUpperCase()}</td>
                                                                <td>{fact.created_at}</td>

                                                            </tr>
                                                        ))
                                                    }
                                                    <tr className="table-secondary fw-bold " >
                                                        <td colSpan={2} className=" text-center ">TOTAUX </td>
                                                        <td>{(totalmontant)?.toLocaleString()} GNF</td>
                                                        <td>{(totalRemise)?.toLocaleString()} GNF </td>
                                                        <td>{(totalNet)?.toLocaleString()} GNF</td>
                                                        <td>{(totalPaye)?.toLocaleString()} GNF</td>
                                                        <td>{(totalReste)?.toLocaleString()} GNF</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <h5 className="mt-2">LISTE DES REGLEMENTS DE FACTURES</h5>
                                            {paiements.length > 0 ?
                                                <table className="table table-striped table-bordered">
                                                    <thead className="table-primary">
                                                        <tr>
                                                            <th>N°FACTURE</th>
                                                            <th>MONTANT</th>
                                                            <th>MAGASIN</th>
                                                            <th>MODE PAIEMENT</th>
                                                            <th>DATE</th>
                                                            {user.droit === "Magasin" && <th>ACTION</th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            paiements?.map((paie, ind) => (
                                                                <tr key={ind}>
                                                                    <td>{paie.numero}</td>
                                                                    <td>{paie.montant?.toLocaleString()}</td>
                                                                    <td>{paie.magasin?.toUpperCase()}</td>
                                                                    <td>{paie.source}</td>
                                                                    <td>{paie.created_at}</td>
                                                                    {user.droit === "Magasin" && <td className="text-end">
                                                                        {paie.duree < 2 && <button className="btn btn-primary" data-bs-toggle='modal' data-bs-target='#editePaie' onClick={() => setSelectPaie(paie)}>
                                                                            <i className="bx bx-edit"></i>
                                                                        </button>
                                                                        }
                                                                    </td>}
                                                                </tr>
                                                            ))
                                                        }
                                                        <tr className="table-secondary fw-bold " >
                                                            <td>TOTAL</td>
                                                            <td>{(totalPaye)?.toLocaleString()} GNF</td>
                                                        </tr>
                                                    </tbody>
                                                </table> : <div className="container mt-2 p-2 bg-danger-subtle text-danger text-center fs-5">Aucun paiement effectué par ce client</div>

                                            }
                                        </div>
                                    </div> : <div className="container mt-2 p-2 bg-danger-subtle text-danger text-center fs-5">Aucune facture pour ce client</div>
                            }
                        </div>}
                        {index === 2 && <div className="dettes">
                            {dettes.length > 0 ?
                                <div className="table-responsive">
                                    <h5 className="mt-2">LISTE DES DETTES INITIALES </h5>
                                    <table className="table table-striped table-bordered">
                                        <thead className="table-primary">
                                            <tr>
                                                <th>MAGASIN</th>
                                                <th>DETTE INITIALE</th>
                                                <th>MONTANT PAYE</th>
                                                <th>RESTE A PAYER</th>
                                                <th>DATE DETTE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dettes?.map((dette, ind) => (
                                                    <tr key={ind}>
                                                        <td>{dette.magasin?.toUpperCase()}</td>
                                                        <td>{dette.dette?.toLocaleString()}</td>
                                                        <td>{dette.paye?.toLocaleString()}</td>
                                                        <td>{dette.reste?.toLocaleString()}</td>
                                                        <td>{dette.date}</td>
                                                    </tr>
                                                ))
                                            }
                                            <tr className="table-secondary fw-bold " >
                                                <td className=" text-center ">TOTAUX </td>
                                                <td>{(totalDette)?.toLocaleString()} GNF</td>
                                                <td>{(totalDettePaye)?.toLocaleString()} GNF </td>
                                                <td>{(totalDetteReste)?.toLocaleString()} GNF</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    {remboursements.length > 0 ?
                                        <>
                                            <h5 className="mt-2">LISTE DES REMBOURSEMENTS DE DETTES </h5>
                                            <table className="table table-striped table-bordered">
                                                <thead className="table-primary">
                                                    <tr>
                                                        <th>MAGASIN</th>
                                                        <th>MONTANT PAYE</th>
                                                        <th>DATE DETTE</th>
                                                        {user.droit === "Magasin" && <th>ACTION</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        remboursements?.map((remb, ind) => (
                                                            <tr key={ind}>
                                                                <td>{remb.magasin?.toUpperCase()}</td>
                                                                <td>{remb.montant?.toLocaleString()}</td>
                                                                <td>{remb.created_at}</td>
                                                                {user.droit === "Magasin" && <td className="text-end">
                                                                    {remb.duree < 2 && <button className="btn btn-primary" data-bs-toggle='modal' data-bs-target='#editePaieDette' onClick={() => setSelectPaie(remb)}>
                                                                        <i className="bx bx-edit"></i>
                                                                    </button>
                                                                    }
                                                                </td>}
                                                            </tr>
                                                        ))
                                                    }
                                                    <tr className="table-secondary fw-bold " >
                                                        <td className=" text-center ">TOTAL </td>
                                                        <td>{(totalDettePaye)?.toLocaleString()} GNF </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </> : <div className="container mt-2 p-2 bg-danger-subtle text-danger text-center fs-5">Aucun remboursement effectué par ce client</div>

                                    }
                                </div> : <div className="container mt-2 p-2 bg-success-subtle text-success text-center fs-5">Ce client n'a pas de dette</div>

                            }
                        </div>}
                        {index === 3 &&
                            <div className="compte">
                                {
                                    <div className="table-responsive">
                                        {versements.length > 0 ?
                                            <>
                                                <h5 className="mt-2">LISTE DES VERSEMENTS </h5>
                                                <table className="table table-striped table-bordered">
                                                    <thead className="table-primary">
                                                        <tr>
                                                            <th>MAGASIN</th>
                                                            <th>MONTANT PAYE</th>
                                                            <th>MAGASIN</th>
                                                            <th>DATE</th>
                                                            {user.droit === "Magasin" && <th>ACTION</th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            versements?.map((vers, ind) => (
                                                                <tr key={ind}>
                                                                    <td>{vers.magasin?.toUpperCase()}</td>
                                                                    <td>{vers.montant?.toLocaleString()}</td>
                                                                    <td>{vers.magasin?.toLocaleString()}</td>
                                                                    <td>{vers.created_at}</td>
                                                                    {user.droit === "Magasin" && <td className="text-end">
                                                                        {(vers.duree < 2 && vers.idmagasin == decryptData('user_profile').idmagasin) && <button className="btn btn-primary" data-bs-toggle='modal' data-bs-target='#editeAvance' onClick={() => setSelectPaie(vers)}>
                                                                            <i className="bx bx-edit"></i>
                                                                        </button>
                                                                        }
                                                                    </td>}
                                                                </tr>
                                                            ))
                                                        }
                                                        <tr className="table-secondary fw-bold " >
                                                            <td className=" text-center ">TOTAL </td>
                                                            <td>{(totalAvance)?.toLocaleString()} GNF </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </> : <div className="container mt-2 p-2 bg-danger-subtle text-danger text-center fs-5">Aucun versement effectué par ce client</div>


                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>
            }
            <EditePaieFacture paie={selectPaie} />
            <EditePaieDette paie={selectPaie} />
            <EditeAvance paie={selectPaie} />
        </div>
    );
}

export default CompteClient;