import { useEffect, useState } from "react";
import { decryptData, defaulUrl } from "../components/const";

const Chomage = () => {

    const [annee, setAnnee] = useState((new Date()).getFullYear())
    const [load, setLoad] = useState(false);
    const [chomage, setChomage] = useState([])
    const handleShowRapport = () => {
        let status = 0;
        setLoad(true)
        fetch(`${defaulUrl}chomage/${annee}`, {
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + decryptData('ACCESS_TOKEN'),
                Accept: 'application/json',
                "Content-Type": "application/json"
            },
        }).then((response) => {
            status = response.status;
            return response.json();
        }).then(data => {
            setLoad(false);
            if (status === 200) {
                setChomage(data);
                console.table(data)
            }
        })
    }
    useEffect(() => {
        handleShowRapport()
    }, [])
    return (
        <div className="chomage">
            {
                load && <div className="loader"></div>
            }
            <h6 className="text-primary">CLASSEMENT ANNUEL  DU PERSONNEL PAR ORDE DE CHOMAGE</h6>
            <div className="row py-2 align-items-center">
                <div className="col-md-2 pe-1 m-2">
                    <div className="form-group">
                        <label htmlFor="" className="form-label">Années</label>
                        <select name="" id="" className="form-select"
                            defaultValue={annee} onChange={(e) => setAnnee(e.target.value)}>
                            <option value="">Choisir une année</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2025">2026</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-1 align-self-end pb-2"> <button className="btn btn-primary" onClick={handleShowRapport}><i className="bx bx-show"></i></button></div>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-hover">
                        <thead className="table-dark">
                            <tr className="align-middle text-center">
                                <th>N°</th>
                                <th>PRENOMS & NOM</th>
                                <th>TELEPHONE</th>
                                <th>FONCTION</th>
                                <th>MAGASIN</th>
                                <th>T. PRESENCE</th>
                                <th>T. ABSENCE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                chomage.map((pers, ind) => (
                                    <tr key={ind}>
                                        <td>{ind + 1}</td>
                                        <td>{pers.nom}</td>
                                        <td>{pers.phone}</td>
                                        <td>{pers.droit === 'Magasin' ? "Vendeur" : pers.droit}</td>
                                        <td>{pers.magasin}</td>
                                        <td className="bg-success-subtle">{pers.nbPresence}</td>
                                        <td className="bg-danger-subtle">{pers.nbAbsence}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>



            </div>
        </div>
    );
}

export default Chomage;