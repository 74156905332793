import React from "react";
import { useRef, useEffect } from "react";
import { redirect, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { decryptData, defaulUrl } from "./const";
const IdleMonitor = ({ onIdle }) => {
    let idleRef = useRef(0).current;
    const navigate = useNavigate()
    useEffect(() => {
        const idleInterval = setInterval(timerIncrement, 30000); // 25 minute

        function timerIncrement() {
            idleRef += 1;
            if (idleRef > 59) {
                onIdle();
                clearInterval(idleInterval);
                navigate('/login')
            }
        }

        function resetIdleRef() {
            idleRef = 0;
        }

        document.body.addEventListener('mousemove', resetIdleRef);
        document.body.addEventListener('keypress', resetIdleRef);
        document.body.addEventListener('scroll', resetIdleRef);
        document.body.addEventListener('click', resetIdleRef);
        return () => {
            document.body.removeEventListener('mousemove', resetIdleRef);
            document.body.removeEventListener('keypress', resetIdleRef);
            document.body.removeEventListener('scroll', resetIdleRef);
            document.body.removeEventListener('click', resetIdleRef);
            clearInterval(idleInterval);
        };
    }, [onIdle]);

    return null;
};
function logout() {
    fetch(`${defaulUrl}logout`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`,
            Accept: 'Application/json'
        }
    }).then((response) => {
        Swal.fire({
            title: 'Session expirée.',
            text: 'Vous avez été déconnecté!',
            icon: 'warning',
            allowOutsideClick: true
        })
    })
    localStorage.clear();
}
const LogoutAuto = () => {
    return (<IdleMonitor onIdle={logout} />);
}

export default LogoutAuto;