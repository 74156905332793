import { useEffect, useRef, useState } from "react";
import SearchField from "../components/SeachField";
import { decryptData, Loading, defaulUrl } from "../components/const";
import ContextMenuFacture from "../Clients/ContextMenuFacture";
import $ from "jquery"
const VenteJour = ({ id }) => {
    const [datejour, setDateJour] = useState((new Date()).toISOString().slice(0, 10));
    const [date1, setDate1] = useState(datejour);
    const [date2, setDate2] = useState(datejour);
    const [isLoading, setIsLoading] = useState(false)
    const tableRef = useRef(null)
    const [factures, setFactures] = useState(null);
    const [totalVente, setTotalMontant] = useState(0);
    const [totalPaye, setPaye] = useState(0);
    const [totalReste, setReste] = useState(0);

    const user = decryptData('USER')
    if (!id) {
        id = decryptData('user_profile').idmagasin;
    }
    const [error, setError] = useState("Aucune vente effectuée pour la periode choisie")




    const handleShowByPeriode = () => {
        setIsLoading(true);
        let status = 0;
        fetch(`${defaulUrl}ventes/${id}/${date1}/${date2}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(da => {
            setIsLoading(false);
            if (status === 200) {
                setFactures(da);
            }
        })
    }
    const handleTotal = () => {
        setTotalMontant(factures?.reduce(
            (previousValue, currentValue) => {
                return previousValue + currentValue.montant

            }, 0))
        setPaye(factures?.reduce(
            (previousValue, currentValue) => {
                return previousValue + currentValue.paye

            }, 0))
        setReste(factures?.reduce(
            (previousValue, currentValue) => {
                return previousValue + currentValue.reste

            }, 0))

    }
    useEffect(() => {
        handleShowByPeriode();

    }, [])
    useEffect(() => {
        handleTotal()
    }, [factures])
    $(tableRef.current).DataTable()
    return (
        <div className="container-fluid p-0">
            <div className="row py-2">
                <h2 className="text-primary">LISTE DES VENTES</h2>
                <div className="col-lg-5 d-flex gap-1">
                    <div className="form-group  col-4">
                        <input type="date" className="form-control" id="date1" name="date1" defaultValue={date1} onChange={(e) => setDate1(e.target.value)} />
                    </div>
                    <div className="form-group col-4 ">
                        <input type="date" className="form-control" id="date2" name="date2" defaultValue={date2} onChange={(e) => setDate2(e.target.value)} />
                    </div>
                    <button type="submit" className="btn btn-primary col-2 align-self-end" onClick={() => handleShowByPeriode()}><i className="bx bx-search" ></i></button>
                </div>
            </div>
            {
                isLoading ? <Loading /> : ((factures?.length || !factures)) ?
                    <div className="vente container-fluid p-0 ">
                        <div className="table-responsive  fill-content">
                            <table ref={tableRef} className="table  table-hover table-striped table-bordered">
                                <thead className="table-dark text-center">
                                    <tr className="align-middle">
                                        <th>N°</th>
                                        <th>N°FACTURE</th>
                                        <th>CLIENT</th>
                                        <th>TELEPHONE</th>
                                        <th>MONTANT</th>
                                        <th>PAYE</th>
                                        <th>RESTE</th>
                                        <th>CONTENEUR</th>
                                        <th>VENDEUR</th>
                                        <th>DATE</th>
                                        <th className="text-end">ACTIONS</th>
                                    </tr>
                                </thead>
                                <tbody className="plage">
                                    {
                                        factures?.map((facture, ind) => (
                                            <tr key={ind} className={`tr align-middle ${facture?.suppression ? "bg-danger-subtle" : ""}`}>
                                                <td>{ind + 1}</td>
                                                <td>{facture?.numero}</td>
                                                <td className="critere">{facture?.client}</td>
                                                <td>{facture?.telephone}</td>
                                                <td>{facture?.montant?.toLocaleString()}</td>
                                                <td>{facture?.paye?.toLocaleString()}</td>
                                                <td>{facture?.reste?.toLocaleString()}</td>
                                                <td>{facture?.conteneur?.toUpperCase()}</td>
                                                <td>{facture?.vendeur?.toUpperCase()}</td>
                                                <td>{facture?.created_at}</td>
                                                <td className="dropdown ms-auto text-end">
                                                    <ContextMenuFacture facture={facture} />
                                                </td>
                                            </tr>

                                        )
                                        )

                                    }
                                    {/* <tr className="bg-secondary-subtle fs-3 fw-bold align-middle text-center">
                                        <td colSpan={4}>TOTAUX</td>
                                        <td>{totalVente?.toLocaleString()} GNF</td>
                                        <td className="text-success">{totalPaye?.toLocaleString()} GNF</td>
                                        <td className="text-danger">{totalReste?.toLocaleString()} GNF</td>

                                    </tr> */}
                                </tbody>
                            </table>
                        </div>

                    </div> : <div className="row p-2 justify-content-center bg-danger-subtle text-danger fs-3">{error}!</div>
            }



        </div>

    );
}

export default VenteJour;