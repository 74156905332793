import { useEffect, useRef, useState } from "react";
import { decryptData, defaulUrl, impression } from "../components/const";

const RapportAdmin = () => {
    const datejour = (new Date()).toISOString().slice(0, 10);
    const [date1, setDate1] = useState(datejour);
    const [date2, setDate2] = useState(datejour);
    const [isLoading, setIsloading] = useState(false);
    const [donnees, setData] = useState(null);
    const [selected, setSelected] = useState(2);
    const [total, setTotal] = useState(0)
    const [totalRemise, setTotalRemise] = useState(0)
    const [totalNet, setTotalNet] = useState(0)
    const [totalPaye, setTotalPaye] = useState(0)
    const [totalReste, setTotalReste] = useState(0);
    const [printTitle, setPrintTitle] = useState("RAPPORT DES VENTES");
    const ref = useRef();
    const handleSend = () => {
        setIsloading(true);
        let status = 0;
        fetch(`${defaulUrl}admin/rapport/${date1}/${date2}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(da => {
            setIsloading(false);
            if (status === 200) {
                setData(da)
                setSelected(2)
            }
        })
    };

    const handleActive = (e) => {
        const items = document.querySelectorAll('.link');
        items.forEach(item => {
            item.classList.remove('active');
        })
        e.target.classList.add('active')
    }
    useEffect(() => {
        handleSend()
    }, [])
    useEffect(() => {
        if (donnees?.ventes?.length) {
            setTotal(donnees?.ventes.reduce((previous, current) => { return previous + current.total }, 0))
            setTotalRemise(donnees?.ventes.reduce((previous, current) => { return previous + current.remise }, 0))
            setTotalNet(donnees?.ventes.reduce((previous, current) => { return previous + current.montant }, 0))
            setTotalPaye(donnees?.ventes.reduce((previous, current) => { return previous + current.paye }, 0))
            setTotalReste(donnees?.ventes.reduce((previous, current) => { return previous + current.reste }, 0))
        }
    }, [donnees])
    return (
        isLoading ? <div className="loader"></div> :
            <div className="rapport">
                <div className="row mb-2  p-3 rounded justify-content-between bg-white">
                    <div className="col-lg-6">
                        <div className="h4 text-primary">FANSAN TEXTIL-COMPTABILITY</div>
                    </div>
                    <div className="bg-white mt-2  w-100 h5 p-2 text-secondary d-flex justify-content-between align-items-center">
                        <div className="col-lg-6 h4 text-secondary ">
                            Rapport Magasins
                        </div>
                        <div className="col-lg-6  ">
                            <div className="modal-footer align-items-center justify-content-end ">
                                <div className="col-lg-4 m-1 text-end ">
                                    <input type="date" className="form-control " id="date1" name="date1" defaultValue={date1} onChange={(e) => setDate1(e.target.value)} />
                                </div>
                                <div className="col-lg-4 m-1 text-end">
                                    <input type="date" className="form-control " id="date1" name="date2" defaultValue={date2} onChange={(e) => setDate2(e.target.value)} />
                                </div>
                                <div className="col-lg-2  text-end w-auto">
                                    <button type="submit" className="btn btn-primary" onClick={() => handleSend()}><i className="bx bx-search"></i></button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row m-0 p-0 bg-primary-subtle rounded">
                    <li hidden className=" fw-bold align-middle p-3   text-center w-auto link " onClick={(e) => { handleActive(e); setSelected(1) }}>TOUT</li>
                    <li className=" fw-bold align-middle p-3   text-center w-auto link active" onClick={(e) => { handleActive(e);setPrintTitle("RAPPORT DES VENTES"); setSelected(2) }}>VENTES</li>
                    <li className=" fw-bold align-middle p-3   text-center w-auto link" onClick={(e) => { handleActive(e);setPrintTitle("LISTE DES REVOUVREMENTS CLIENT"); setSelected(3) }}>RECOUVREMENTS</li>
                    <li className=" fw-bold align-middle p-3   text-center w-auto link" onClick={(e) => { handleActive(e);setPrintTitle("RAPPORT DES ENTREES DE CAISSE"); setSelected(4) }}>ENTREES</li>
                    <li className="fw-bold  align-middle p-3   text-center w-auto link" onClick={(e) => { handleActive(e);setPrintTitle("RAPPORT DES SORTIES DE CAISSE"); setSelected(5) }}>SORTIES</li>
                    <li className="fw-bold  align-middle p-3   text-center w-auto link" onClick={(e) => { handleActive(e);setPrintTitle("RAPPORT DES VERSEMENTS EFFECTUES"); setSelected(6) }}>VERSEMENTNS</li>
                </div>
                <div className="row justify-content-end p-2">
                    <div className="col-lg-1 text-end">
                        <button className="btn btn-primary" onClick={() => impression(ref.current, 'landscape', printTitle?.toUpperCase())}>
                            <i className="bx bx-printer"></i></button>
                    </div>
                </div>
                <div className="row" ref={ref}>
                    {selected === 1 &&
                        <div className="table responsive mt-2" >
                            <table className="table table-striped table-hover table-bordered">
                                <thead className="table-dark  border-white text-center">
                                    <th>N°</th>
                                    <th>MAGASIN</th>
                                    <th>ENTRE</th>
                                    <th>SORTIE</th>
                                    <th>BALANCE</th>
                                    <th>DATES</th>
                                </thead>
                            </table>
                        </div>
                    }
                    {selected === 2 && <div className="row ventes p-2 justify-content-center">
                        {donnees?.ventes?.length ? <div className="table responsive mt-2" >
                            <table className="table table-striped table-hover table-bordered">
                                <thead className="table-dark  border-white text-center">
                                    <tr>
                                        <th>N°FACT</th>
                                        <th>MONTANT</th>
                                        <th>REMISE</th>
                                        <th>MONTANT NET</th>
                                        <th>PAYE</th>
                                        <th>RESTE</th>
                                        <th>MAGASIN</th>
                                        <th>DATES</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        donnees?.ventes.map((fac, ind) => (
                                            <tr key={ind}>
                                                <td>{fac.numero}</td>
                                                <td>{fac.total?.toLocaleString()}</td>
                                                <td>{fac.remise?.toLocaleString()}</td>
                                                <td>{fac.montant?.toLocaleString()}</td>
                                                <td>{fac.paye?.toLocaleString()}</td>
                                                <td>{fac.reste?.toLocaleString()}</td>
                                                <td>{fac.magasin}</td>
                                                <td>{fac.created_at}</td>
                                            </tr>
                                        ))
                                    }
                                    <tr>
                                        <td className="text-center fw-bold fs-4">Total</td>
                                        <td className="fw-bold">
                                            {total?.toLocaleString()} GNF
                                        </td>
                                        <td className="fw-bold">
                                            {totalRemise?.toLocaleString()} GNF
                                        </td>
                                        <td className="fw-bold">
                                            {totalNet?.toLocaleString()} GNF
                                        </td>
                                        <td className="fw-bold">
                                            {totalPaye?.toLocaleString()} GNF
                                        </td>
                                        <td className="fw-bold">
                                            {totalReste?.toLocaleString()} GNF
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> : <div className="row bg-danger-subtle text-danger fs-3 p-2 justify-content-center">Aucune vente effectuee</div>}
                    </div>
                    }
                    {selected === 3 && <div className="row recouvrements p-2 justify-content-center">
                        {
                            donnees?.remboursements?.length ? <div className="table responsive mt-2" >
                                <table className="table table-striped table-hover table-bordered">
                                    <thead className="table-dark  border-white text-center">
                                        <tr>
                                            <th>N°FACT</th>
                                            <th>MAGASIN</th>
                                            <th>CLIENT</th>
                                            <th>MONTANT</th>
                                            <th>DATES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            donnees?.remboursements.map((remb, ind) => (
                                                <tr key={ind}>
                                                    <td>{remb.numero}</td>
                                                    <td>{remb.magasin}</td>
                                                    <td>{remb.client}</td>
                                                    <td>{remb.montant?.toLocaleString()}</td>
                                                    <td>{(new Date(remb.created_at))?.toLocaleDateString()}</td>
                                                </tr>
                                            ))
                                        }
                                        <tr>
                                            <td colSpan={3} className="text-center fw-bold fs-4">Total</td>
                                            <td className="fw-bold">
                                                {donnees?.totaux.recouvrement?.toLocaleString()}
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div> : <div className="row bg-danger-subtle text-danger fs-3 p-2 justify-content-center">Aucun remboursement effectue</div>
                        }
                    </div>
                    }
                    {selected === 4 && <div className="row entree p-2 justify-content-center">
                        {
                            donnees?.entree?.length ? <div className="table responsive mt-2" >
                                <table className="table table-striped table-hover table-bordered">
                                    <thead className="table-dark  border-white text-center">
                                        <tr>
                                            <th>N°</th>
                                            <th>MAGASIN</th>
                                            <th>DESCRIPTIONS</th>
                                            <th>MONTANT</th>
                                            <th>DATES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            donnees.entree.map((ent, ind) => (
                                                <tr key={ind}>
                                                    <td>{ind + 1}</td>
                                                    <td>{ent.magasin}</td>
                                                    <td>{ent.description}</td>
                                                    <td>{ent.montant?.toLocaleString()}</td>
                                                    <td>{(new Date(ent.date))?.toLocaleDateString()}</td>
                                                </tr>
                                            ))
                                        }
                                        <tr>
                                            <td colSpan={3} className="text-center fw-bold fs-4">Total</td>
                                            <td className="fw-bold">
                                                {donnees?.totaux.entree?.toLocaleString()}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> : <div className="row bg-danger-subtle text-danger fs-3 p-2 justify-content-center">Aucune entrée effectuee</div>
                        }
                    </div>

                    }
                    {selected === 5 && <div className="row sortie p-2 justify-content-center">
                        {
                            donnees?.sorties?.length ? <div className="table responsive mt-2" >
                                <table className="table table-striped table-hover table-bordered">
                                    <thead className="table-dark  border-white text-center">
                                        <tr>
                                            <th>N°</th>
                                            <th>MAGASIN</th>
                                            <th>DESCRIPTIONS</th>
                                            <th>TYPE</th>
                                            <th>MONTANT</th>
                                            <th>DATES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            donnees.sorties.map((ent, ind) => (
                                                <tr key={ind}>
                                                    <td>{ind + 1}</td>
                                                    <td>{ent.magasin}</td>
                                                    <td>{ent.description}</td>
                                                    <td>{ent.type}</td>
                                                    <td>{ent.montant?.toLocaleString()}</td>
                                                    <td>{(new Date(ent.date))?.toLocaleDateString()}</td>
                                                </tr>
                                            ))
                                        }
                                        <tr>
                                            <td colSpan={4} className="text-center fw-bold fs-4">Total</td>
                                            <td className="fw-bold">
                                                {donnees?.totaux.sortie?.toLocaleString()}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> : <div className="row bg-danger-subtle text-danger fs-3 p-2 justify-content-center">Aucune sortie effectuee</div>
                        }
                    </div>
                    }

                    {selected === 6 && <div className="row ventes p-2 justify-content-center">
                        {donnees?.versements?.length ? <div className="table responsive mt-2" >
                            <table className="table table-striped table-hover table-bordered">
                                <thead className="table-dark  border-white text-center">
                                    <tr>
                                        <th>N°</th>
                                        <th>MAGASIN</th>
                                        <th>MONTANT</th>
                                        <th>DATES</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        donnees?.versements.map((recep, ind) => (
                                            <tr key={ind}>
                                                <td>{ind + 1}</td>
                                                <td>{recep.magasin}</td>
                                                <td>{recep.montant?.toLocaleString()}</td>
                                                <td>{(new Date(recep.created_at))?.toLocaleDateString()}</td>
                                            </tr>
                                        ))
                                    }
                                    <tr>
                                        <td colSpan={2} className="text-center fw-bold fs-4">Total</td>
                                        <td className="fw-bold">
                                            {donnees?.totaux.versement?.toLocaleString()} GNF
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> : <div className="row bg-danger-subtle text-danger fs-3 p-2 justify-content-center">Aucun versement effectue</div>}
                    </div>
                    }
                </div>
            </div>
    );
}

export default RapportAdmin;