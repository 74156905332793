import { NavLink } from "react-router-dom";
import { decryptData, Loading } from "../components/const";
import UseFetch from "../components/UseFetch";
import ModalAddClient from "./ModalAddClient";
import { useEffect, useRef, useState } from "react";
import ModalAvanceClient from "./ModalAvanceClient";
import ModalEditeCLient from "./ModalEditeClient";
import $ from "jquery";








const Clients = ({ id }) => {




    const user = decryptData('USER')
    let idmagasin = 0;
    const isMagasin = user.droit === 'Magasin';
    const [selectedClient, setselectedClient] = useState(null)
    const [totalSolde, setToltalSolde] = useState(0);
    if (isMagasin) {
        idmagasin = decryptData('user_profile').idmagasin
    }
    if (id) {
        idmagasin = id;
    }
    const { data: clients, isloading } = UseFetch(`clients/${idmagasin}`);
    useEffect(() => {
        setToltalSolde(clients?.reduce((previous, current) => { return previous + current.solde }, 0))
        $(tableRef.current).DataTable();
    }, [clients])

    const tableRef = useRef(null);

    return (
        isloading ? <Loading /> :
            <div className="row">
                <div className="row justify content between align-items-center">
                    <div className="col-lg-8 text-primary h3">Gestion des clients</div>
                    <div className="col-lg-4 text-end">
                        {user.droit === 'Magasin' && <div className="text-end p-3">
                            <button className="btn btn-primary" data-bs-toggle='modal' data-bs-target='#modalAddClient'>Nouveau client</button>
                        </div>}
                    </div>
                </div>
                {
                    clients?.length > 0 ?
                        <div className="row">
                            <div className="table-responsive">
                                <table ref={tableRef} className="table table-striped  table-hover ">
                                    <thead className="table-primary">
                                        <tr className="align-middle">
                                            <th>N°</th>
                                            <th>CLIENT</th>
                                            <th>TELEPHONE</th>
                                            <th>SOLDE</th>
                                            <th>CREDIT TOTAL</th>
                                            <th>DETTE MAX</th>
                                            <th>AGENT</th>
                                            {isMagasin && <th className="text-end">ACTIONS</th>}
                                        </tr>
                                    </thead>
                                    <tbody className="plage">
                                        {
                                            clients.map((client, ind) => (
                                                <tr key={ind} className="tr align-middle">
                                                    <td>{ind + 1}</td>
                                                    <td className="critere">{client.client}</td>
                                                    <td>{client.telephone}</td>
                                                    <td>{client.solde?.toLocaleString()} GNF</td>
                                                    <td>{client.creditTotal?.toLocaleString()}</td>
                                                    <td>{client.dettemax?.toLocaleString()}</td>

                                                    <td>{client?.agent?.toUpperCase()}</td>
                                                    {isMagasin &&

                                                        <td className="text-end">
                                                            <button data-bs-toggle="modal" data-bs-target="#modalAvance" className="btn btn-success m-1" title="Faire un versement" onClick={() => setselectedClient(client)} >
                                                                <i className="bx bx-dollar-circle"></i>
                                                                Avance
                                                            </button>
                                                            <button data-bs-toggle="modal" data-bs-target="#modalEditeClient" className="btn btn-primary m-1" title="Modifier" onClick={() => setselectedClient(client)} >
                                                                <i className="bx bx-edit"></i>
                                                            </button>
                                                            <NavLink to='/client/compte' state={{ client }} className="btn btn-primary m-1" title="Voir factures"><i className="bx bx-show"></i> </NavLink>


                                                        </td>}
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div> :
                        <div className="row p-2 justify-content-center bg-danger-subtle text-danger fs-3 mt-2">
                            Vous n'avez aucun client enregistré
                        </div>
                }
                <ModalAddClient listClient={clients} />
                <ModalEditeCLient client={selectedClient} />
                <ModalAvanceClient client={selectedClient} />
            </div>
    );
}

export default Clients;