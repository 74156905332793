import Swal from "sweetalert2";
import { decryptData, defaulUrl } from "../components/const";
import { NavLink } from "react-router-dom";
const ContextMenuFacture = ({ facture }) => {
    const user = decryptData('USER');

    const handleDeleteRequest = () => {
        Swal.fire({
            title: "Demande de suppression",
            text: `Souhaitez-vous soumettre cette demande de suppresion de la facture N°${facture.numero} du client: ${facture?.client?.toUpperCase()} ?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'OUI',
            cancelButtonText: 'Annuler',
            cancelButtonColor: 'red',
            confirmButtonColor: 'green'
        }).then(confirm => {
            if (confirm.isConfirmed) {
                let status = 0;
                fetch(`${defaulUrl}facture/${facture.idfacture}/delete-request`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
                    },
                    body: JSON.stringify({ suppression: "Demande encours" })
                }).then(response => {
                    status = response.status;
                    return response.json();
                }).then(data => {
                    if (status === 200) {
                        Swal.fire({
                            title: "Success",
                            text: data.message,
                            icon: 'success',
                        })
                    }
                })


            }
        })
    }
    return (
        <div>
            <a className="dropdown-toggle dropdown-toggle-nocaret" href="#"
                data-bs-toggle="dropdown"  >
                <i className='bx bx-dots-vertical-rounded font-22 text-option px-2 bg-primary-subtle shadow rounded-circle'></i>
            </a>
            <ul className="dropdown-menu z-3">

                {user.droit === 'Magasin' &&
                    <>
                        <li>
                            <NavLink className="dropdown-item fs-6" to={`/print/${facture.idfacture}`}> <i className="bx bx-printer text-danger"></i> imprimer</NavLink>
                        </li>
                        {(facture?.reste > 0) && <> <li>
                            <hr className="dropdown-divider" />
                        </li>
                            <li>
                                <NavLink className="dropdown-item" state={{ facture }} to='/facture/paiement'>
                                    <i className="bx bx-credit-card text-success"></i> Encaisser</NavLink>
                            </li>
                        </>}
                        <li>
                            <hr className="dropdown-divider" />
                        </li>
                    </>
                }
                <li><NavLink to='/paiements' className="dropdown-item" state={{ facture }}><i className="bx bx-check text-success"></i> Détail</NavLink>
                </li>
                <li>
                    <hr className="dropdown-divider" />
                </li>
                {(user.droit === 'Magasin' && (!facture?.suppression)) &&
                    <>
                        {(facture.duree <= 2) &&
                            <>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <NavLink className="dropdown-item" to='/facture/editer' state={{ facture }} >
                                        <i className="bx bx-edit text-primary"></i> Modifier
                                    </NavLink>
                                </li>
                            </>
                        }
                        {(facture.duree <= 180) &&
                            <>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <button className="dropdown-item" onClick={handleDeleteRequest}>
                                        <i className="bx bx-trash text-danger"></i>
                                        Demande supp.
                                    </button>
                                </li>
                            </>
                        }

                    </>
                }
            </ul>

        </div>
    );
}

export default ContextMenuFacture;