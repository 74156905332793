
import UseFetch from "../components/UseFetch";
const DbAdmin = () => {
    const { data: dashboard, isloading } = UseFetch('dashboard/admin')
    dashboard?.magasins?.sort((a, b) => (b.solde - a.solde))
    return (
        isloading ? <div className="loader"></div> :
            <div className="dashboard">
                <div className="row mb-2  p-3 rounded justify-content-between bg-white">
                    <div className="col-lg-6">
                        <div className="h4 text-primary">FANSAN TEXTIL-ADMINISTRATION</div>
                        <span className="text-secondary fs-5">Tableau de bord</span>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
                    <div className="col my-1">
                        <div className="card radius-10 border-start border-0 border-3 border-primary h-100">
                            <div className="py-4 card-body">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <p className="mb-0 text-secondary">Total Magasins</p>
                                        <h5 className="my -1 text-primary">{dashboard?.nbMagasin}</h5>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col my-1">
                        <div className="card radius-10 border-start border-0 border-3 border-danger h-100">
                            <div className="py-4 card-body">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <p className="mb-0 text-secondary">Dette fournisseurs</p>
                                        {dashboard?.dette_fourn ?
                                            <ul className="text-danger">
                                                {
                                                    dashboard?.dette_fourn.map((dette, ind) => (
                                                        <li key={ind}>
                                                            {(+dette.dette)?.toLocaleString()} {dette.devise}</li>
                                                    ))
                                                }
                                            </ul> : <div className="text-info">00</div>}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col my-1">
                        <div className="card radius-10 border-start border-0 border-3 border-info h-100">
                            <div className="py-4 card-body">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <p className="mb-0 text-secondary">Dette clients</p>
                                        <ul>
                                            <li className="my-1 text-info fs-6">Dettes initiales: {(+dashboard?.dette_initiale)?.toLocaleString()} GNF</li>
                                            <li className="my-2 text-info fs-6">Dettes Factures: {(+dashboard?.dette_facture)?.toLocaleString()} GNF</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col my-1" >
                        <div className="card radius-10 border-start border-0 border-3 border-success h-100">
                            <div className="py-4 card-body">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <p className="mb-0 text-secondary">Caisse comptable</p>
                                        <h5 className="my-1  text-success">{dashboard?.caisse?.toLocaleString()} GNF</h5>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <h4 className="text-secondary">LISTE DE MAGASINS AVEC LEUR SOLDE ACTUEL</h4>

                    <div className="card p-0">
                        <div className="card-body p-0">
                            <div id="invoice">
                                <div className="table-responsive invoice" >
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>N°</th>
                                                <th className="text-start fw">MAGASIN</th>
                                                <th className="text-start fw">TELEPHONE</th>
                                                <th className="text-end fw">SOLDE ACTUEL</th>
                                            </tr>
                                        </thead>
                                        {dashboard?.magasins ? <tbody>
                                            {dashboard?.magasins?.map((mag, ind) =>
                                                <tr key={ind}>
                                                    <td className="no text-center">{ind + 1}</td>
                                                    <td className="text-start">{mag.magasin.toUpperCase()}</td>
                                                    <td className="text-start">{(+mag.telephone)?.toLocaleString()}</td>
                                                    <td className="no text-end">{mag.solde?.toLocaleString()} GNF</td>
                                                </tr>
                                            )}

                                        </tbody>
                                            : <tr className="text-danger  p-3 text-center bg-danger-subtle">
                                                <td colSpan={5} className="fs-3"> Auncun magasin trouvee</td>
                                            </tr>

                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default DbAdmin;