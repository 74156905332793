import { useEffect, useRef, useState } from "react";
import { decryptData } from "../components/const";
import SearchField from "../components/SeachField";
import UseFetch from '../components/UseFetch';
import PayerDette from "./ModalPayerDette";
import { NavLink } from "react-router-dom";
import $ from "jquery"
const DetteInitiale = () => {
    const profile = decryptData('user_profile');
    const { data: dettes, isloading } = UseFetch(`dettes/${profile.idmagasin}`);
    const [totalDette, setTotalDette] = useState(0);
    const [totalPaye, setTotalPaye] = useState(0);
    const [totalReste, setTotalReste] = useState(0);
    const [selectedclient, setSelectedClient] = useState(null);
    const tableRef = useRef(null)
    useEffect(() => {
        setTotalDette(dettes?.reduce((previous, current) => { return previous + current.dette }, 0))
        setTotalPaye(dettes?.reduce((previous, current) => { return previous + current.paye }, 0))
        setTotalReste(dettes?.reduce((previous, current) => { return previous + current.reste }, 0))

    }, [dettes?.length]);
    $(tableRef.current).DataTable();

    return (
        isloading ? <div className="loader"></div> : dettes?.length ? <div className="dette">
            <div className="row p-2 justify-content-between align-items-center">
                <div className="col-lg-4 text-primary h3">
                    Clients en dette
                </div>
                <div className="table-responsive">
                    <table ref={tableRef} className="table table-hover table-striped table-bordered">
                        <thead className="table-primary">
                            <tr>
                                <th>N°</th>
                                <th>CLIENT</th>
                                <th>TELEPHONE</th>
                                <th>AGENT</th>
                                <th>DETTE</th>
                                <th>PAYE</th>
                                <th>RESTE</th>
                                <th className="text-end">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody className="plage">
                            {
                                dettes?.map((client, ind) => (
                                    <tr className="tr align-middle" key={ind}>
                                        <td>{ind + 1}</td>
                                        <td className="critere">{client.client}</td>
                                        <td>{client?.telephone}</td>
                                        <td>{client.agent}</td>
                                        <td>{client?.dette.toLocaleString()}</td>
                                        <td>{client?.paye.toLocaleString()}</td>
                                        <td>{client?.reste.toLocaleString()}</td>
                                        <td className="text-end">
                                            <button className="btn btn-success m-1" data-bs-toggle="modal" data-bs-target="#modalverser" onClick={() => setSelectedClient(client)}>
                                                <i className="bx bx-dollar-circle" ></i>
                                                Remb...
                                            </button>

                                            <NavLink to='/client/compte' state={{ client }} className="btn btn-primary m-1" >
                                                <i className="bx bx-show"></i> </NavLink>
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>
            </div>
            <PayerDette dette={selectedclient} />
        </div> : <div className="w-100 fs- p-2 text-center text-danger bg-danger-subtle"> Aucun client n'a une dette initiale</div>
    );
}

export default DetteInitiale;