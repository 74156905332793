import { useState, useEffect, useRef } from "react";
import { decryptData, Loading } from "../components/const";
import SearchField from "../components/SeachField";
import UseFetch from "../components/UseFetch";
import { NavLink } from "react-router-dom";
import $ from "jquery"
const SoldeClient = ({ idmagasin }) => {
    const profile = decryptData('user_profile');
    if (!idmagasin) {
        idmagasin = profile.idmagasin
    }
    let { data: clients, isloading } = UseFetch(`clients/solde/${idmagasin}`);
    const [totalDette, settotalDette] = useState(0);
    const tableRef = useRef()
    clients?.sort((a, b) => a.solde - b.solde)
    useEffect(() => {
        settotalDette(clients?.reduce((previousValue, current) => { return (previousValue) + (+current.solde) }, 0))
    }, [clients])
    $(tableRef.current).DataTable()
    return (
        isloading ? <Loading /> : clients?.length ? <div className="row">
            <h3 className="text-primary fs-3">Liste des client redevables</h3>
            <div className="table-responsive">
                <table ref={tableRef} className="table table-hover table-striped table-bordered" >
                    <thead className="table-primary">
                        <tr>
                            <th>N°</th>
                            <th>CLIENT</th>
                            <th>TELEPHONE</th>
                            <th>ADRESSE</th>
                            <th>DETTES</th>
                            <th>AGENT</th>
                            <th className="text-end">ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody className="plage">
                        {
                            clients.map((sol, ind) => (
                                sol.solde > 0 && <tr key={ind} className="tr">
                                    <td>{ind + 1}</td>
                                    <td className="critere">{sol.client}</td>
                                    <td>{sol.telephone}</td>
                                    <td>{sol.adresse}</td>
                                    <td>{(+sol.solde)?.toLocaleString()} GNF</td>
                                    <td>{(sol.agent)?.toUpperCase()}</td>
                                    <td className="text-end">
                                        <NavLink to={`/mag/${idmagasin}/client/${sol.id}/factures`} className="btn btn-success"  >
                                            Voir factures
                                        </NavLink>
                                    </td>
                                </tr>
                            ))
                        }
                        {/* <tr className="fw-bold fs-3 text-center bg-danger-subtle">
                            <td colSpan={4}>TOTAL DETTE</td>
                            <td >{totalDette?.toLocaleString()} GNF</td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
        </div> : <div className="row p-2 bg-danger-subtle text-danger justify-content-center fs-3">Aucun client trouvé!</div>
    );
}

export default SoldeClient;