import { formatMoney, Loading, onfocus, defaulUrl, decryptData } from "../components/const";
import Swal from "sweetalert2";

import { useState, useRef, useEffect } from "react";
const ModalDepense = ({ conteneur }) => {
    const [paye, setPaye] = useState(0)
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [toPaye, setToPaye] = useState(0);
    const [id_dep, setIdDep] = useState(null);
    const [source, setSource] = useState('');
    const [motif, setMotif] = useState(null);
    const ref = useRef();
    const myModalEl = document.getElementById('modalDepense')
    if (myModalEl) {
        myModalEl.addEventListener('hidden.bs.modal', event => {
            setIdDep('')
            setToPaye(0)
            setPaye(0)
        })
    }

    useEffect(() => {
        setToPaye(conteneur?.depenses?.find((dep) => +dep.id === +id_dep)?.reste ?? 0)
    }, [id_dep])
    const handlePayer = () => {
        if (paye > (toPaye)) {
            setErrors({ 'montant': 'Vous avez saisi un montant trop élévé!' });
            return
        }
        let status = 0;
        setIsLoading(true)
        const formData = new FormData();
        formData.set('idconteneur', conteneur?.id)
        formData.set('montant', +paye)
        formData.set('id_dep', id_dep)
        formData.set('motif', motif)
        formData.set('source', source)
        fetch(`${defaulUrl}depense/add`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                ContentType: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },
            body: formData
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(data => {
            console.log(data)
            setIsLoading(false);
            if (status === 200) {
                Swal.fire({
                    title: 'Succès',
                    text: data.message,
                    icon: 'success'
                }).then(() => {
                    setPaye(0);
                    ref.current.click();
                })
                setErrors(null)
            } else {
                setErrors(data.errors);
            }
        })
        setErrors(null);
    }

    return (
        <div className="modal fade " id="modalDepense" aria-labelledby="exampleModalLabel" aria-hidden="true" >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-secondary-subtle">
                        <h1 className="modal-title fs-5">Enregistrer une depense # {conteneur?.numero}</h1>
                        <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form >
                        <div className="modal-body position-relative">
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Destinataire</label>
                                <select name="" id="" className="form-select"
                                    onChange={(e) => setIdDep(e.target.value)}
                                    onBlur={(e) => setIdDep(e.target.value)}
                                >
                                    <option value="">Votre choix</option>
                                    {
                                        conteneur?.depenses?.map((dep, ind) => (
                                            dep?.montant && <option value={dep.id} key={ind}>{dep.description}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Montant à payer</label>
                                <div className="form-control hide-arrow p-2 bg-secondary-subtle">{toPaye.toLocaleString()} GNF</div>
                            </div>
                            <label htmlFor="" className="fs-5">Montant payé</label>
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" value={paye}
                                        onChange={(e) => {
                                            setPaye(e.target.value.replace(/\s/g, ""));
                                        }}
                                        onBlur={(e) => formatMoney(e)}
                                        onFocus={(e) => onfocus(e)}
                                    />
                                    <span className="input-group-text" id="basic-addon2">GNF</span>
                                </div>
                                {errors?.montant && <span className="text-danger p-1">{errors?.montant}</span>
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Source</label>
                                <select name="" id="" className="form-select"
                                    onChange={(e) => setSource(e.target.value)}

                                >
                                    <option value="">Votre choix</option>
                                    <option value="caisse">Caisse</option>
                                    <option value="Banque">Banque</option>
                                    <option value="Autres">Autres</option>

                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Motif</label>
                                <textarea name="motif" id="motif" className="form-control" defaultValue={motif} onChange={(e) => setMotif(e.target.value)} placeholder="Entrez le motif ici">
                                </textarea>
                            </div>
                            {isLoading && <Loading />}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" ref={ref}>Annuler</button>
                            <button type="button" className="btn btn-success" onClick={() => handlePayer()} disabled={isLoading}>Payer</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
}

export default ModalDepense;