import { NavLink } from "react-router-dom";
import UseFetch from "../components/UseFetch";
import { useEffect, useRef, useState } from "react";
import { decryptData } from "../components/const";
import EditeDetteMax from "../Clients/EditeDetteMax";
import ModalEditeCLient from "../Clients/ModalEditeClient";
import $ from "jquery"
const AllClients = () => {
    const { data: clients, isloading } = UseFetch(`clients`)
    const [selectedClient, setSelect] = useState(null);
    const tableRef = useRef(null)

    $(tableRef.current).DataTable()
    return (
        (isloading || clients?.length < 0) ? <div className="loader"></div> :
            <div className="client">
                <div className="row justify content between align-items-center">
                    <div className="col-lg-8 text-primary h3">LISTE GENERALE DES CLIENTS</div>
                </div>
                {
                    <div className="row">
                        <div className="table-responsive">
                            <table ref={tableRef} className="table table-hover table-striped table-bordered">
                                <thead className="table-primary">
                                    <tr>
                                        <th>N°</th>
                                        <th>CLIENT</th>
                                        <th>TELEPHONE</th>
                                        <th>ADRESSE</th>
                                        <th>SOLDE TOTAL</th>
                                        <th>CREDIT TOTAL</th>
                                        <th>DETTES MAX</th>
                                        <th>AGENT</th>
                                        <th className="text-end">ACTIONS</th>
                                    </tr>
                                </thead>
                                <tbody className="plage">
                                    {
                                        clients?.map((client, ind) => (
                                            <tr key={ind} className="tr align-middle">
                                                <td>{ind + 1}</td>
                                                <td className="critere">{client.client}</td>
                                                <td>{client.telephone}</td>
                                                <td>{client.adresse}</td>
                                                <td>{client.solde?.toLocaleString()} GNF</td>
                                                <td>{client.creditTotal?.toLocaleString()} GNF</td>
                                                <td>{client.dettemax?.toLocaleString()} GNF</td>

                                                <td>{client.agent}</td>
                                                <td className="text-end">
                                                    <button className=" btn btn-primary m-1"
                                                        onClick={() => setSelect(client)}
                                                        data-bs-toggle="modal" data-bs-target="#modalEditeClient"><i className="bx bx-edit"></i></button>
                                                    <NavLink to='/client/compte' state={{ client }} className="btn btn-primary m-1" title="Voir factures"><i className="bx bx-show"></i></NavLink>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                }
                <EditeDetteMax client={selectedClient} />

                <ModalEditeCLient client={selectedClient} />
            </div>
    );
}

export default AllClients;