import { Loading, decryptData } from "../components/const";
import { useEffect, useRef, useState } from "react";
import SearchField from "../components/SeachField";
import UseFetch from "../components/UseFetch";
import ContextMenuFacture from "./ContextMenuFacture";
import $ from "jquery"
const FactureNonPayees = ({ idmagasin }) => {
    const [totalDette, settotalDette] = useState(0);
    const profile = decryptData('user_profile');
    if (!idmagasin) {
        idmagasin = profile.idmagasin
    }
    const tableRef = useRef(null)
    let { data: factures, isloading } = UseFetch(`ventes/factures/${idmagasin}`);
    factures?.sort((a, b) => a.idfacture - b.idfacture);
    $(tableRef.current).DataTable()
    useEffect(() => {
        settotalDette(factures?.reduce((previousValue, current) => { return (previousValue) + (+current.reste) }, 0))
    }, [factures])
    return (
        isloading ? <Loading /> : factures?.length ? <div className="row">
            <div className="row p-2 justify-content-between align-items-center">
                <div className="col-lg-4 text-primary h3">
                    Factures impayées
                </div>
                <div className="table-responsive">
                    <table ref={tableRef} className="table table-hover table-striped table-bordered">
                        <thead className="table-primary">
                            <tr>
                                <th>N°FACTURE</th>
                                <th>CLIENT</th>
                                <th>TELEPHONE</th>
                                <th>ADRESSE</th>
                                <th>MONTANT</th>
                                <th>PAYE</th>
                                <th>RESTE</th>
                                <th>CONTENEUR</th>
                                <th>VENDEUR</th>
                                <th>DATE</th>
                                <th className="text-end">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody className="plage">
                            {
                                factures.map((fact, ind) => (
                                    <tr key={ind} className={`tr align-middle ${fact?.suppression ? "bg-danger-subtle" : ""}`}>
                                        <td>{fact.numero}</td>
                                        <td className="critere">{fact.client}</td>
                                        <td>{fact.telephone}</td>
                                        <td>{fact.adresse}</td>
                                        <td>{(+fact.montant).toLocaleString()}</td>
                                        <td>{(+fact.paye).toLocaleString()}</td>
                                        <td>{(+fact.reste).toLocaleString()}</td>
                                        <td>{(fact.conteneur)?.toUpperCase()}</td>
                                        <td>{(fact.vendeur)?.toUpperCase()}</td>
                                        <td>{fact.created_at}</td>
                                        <td className="text-end">
                                            <ContextMenuFacture facture={fact} />
                                        </td>
                                    </tr>
                                ))
                            }
                            {/* <tr className="fw-bold fs-3 text-center bg-danger-subtle">
                                <td colSpan={6}>TOTAL DETTE</td>
                                <td colSpan={2}>{totalDette?.toLocaleString()} GNF</td>
                            </tr> */}
                        </tbody>
                    </table>
                </div>
            </div>
        </div> : <div className="row p-2 bg-danger-subtle text-danger justify-content-center fs-3">Aucune facture impayées!</div>
    );
}

export default FactureNonPayees;