import React from 'react';
import ReactDOM from 'react-dom/client';





// bootstrapp
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.min.css'

import 'datatables.net-bs5/css/dataTables.bootstrap5.css'
import 'datatables.net-bs5/js/dataTables.bootstrap5'
import "datatables.net-bs5"; // DataTables Bootstrap 5 integration






// -----css--------------
import './assets/plugins/simplebar/css/simplebar.css'
import './assets/css/pace.min.css'
import './assets/css/bootstrap.min.css'
import './assets/css/app.css'
import './assets/css/icons.css'
import './assets/css/dark-theme.css'
import './assets/css/semi-dark.css'
import './assets/css/header-colors.css'

import { ConstextProvider } from './components/ContextProvider.js';
import { RouterProvider } from 'react-router-dom';
import router from './router.js';

import './css/style.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConstextProvider>
      <RouterProvider router={router} />
    </ConstextProvider>
  </React.StrictMode>
);

