import { useEffect, useState } from "react";
import { decryptData, defaulUrl, formatMoney, Loading, onfocus } from "../components/const";
import UseFetch from "../components/UseFetch";
import Swal from "sweetalert2";
import ModalAddClient from "../Clients/ModalAddClient";
import Select from "react-select";
const Facturation = () => {
    let id = decryptData('user_profile').idmagasin
    let { data: produits, isloading } = UseFetch(`mag/${id}/stocks`);
    let infoConteneur = produits?.map(p => ({ 'id': p.idconteneur, 'numero': p.numero, 'conteneur': p.conteneur }));
    const [produitsParConteneur, setProduitsParConteneur] = useState(null);
    const { data: clients, isloading: loader } = UseFetch(`clients`)
    infoConteneur = [...new Map(infoConteneur?.map(o => [o.numero, o])).values()];
    const [selected, setSelect] = useState([]);
    const [gonext, setGonext] = useState(false);
    const [isLoading, setIsloadind] = useState(false)
    const [client, setClient] = useState({ value: '978', label: 'PASSANT' });
    const [total, setTotal] = useState(0);
    const [remise, setRemise] = useState(0);
    const [net, setNet] = useState(0);
    const [paye, setPaye] = useState(0);
    const [selectedConteneur, setSelectedConteneur] = useState(null);
    const [error, setError] = useState(null);
    const [sourceSolde, setsourceSolde] = useState(false);
    const [surplus, setSurplus] = useState(0)


    const handleshowProducts = () => {
        if (!selectedConteneur) {
            setError('Veuillez choisir un conteneur!');
            return
        }
        setProduitsParConteneur(produits?.filter(p => +p.idconteneur === +selectedConteneur.value));
        setSelect([]);
    }
    const handSelect = (prod) => {
        if (!(selected.find(p => (p.id === prod.idstock && p.pvu === prod.pvu && prod.produit === p.produit)))) {
            setSelect([
                ...selected,
                {
                    'idproduit': prod.idproduit,
                    'produit': prod.produit,
                    'quantite': 1,
                    'pvu': prod.pvu,
                    'idstock': prod.id,
                    'qte_total': +prod.stock,
                    'idconteneur': prod.idconteneur
                }
            ]);
        }

    }
    const handleTotal = () => {
        setTotal(selected?.reduce(
            (previousValue, currentValue) => { return previousValue + currentValue.quantite * currentValue.pvu },
            0))
    }
    const handleSave = () => {
        if (!client) {
            Swal.fire({
                title: "Informations manquante!",
                text: "Veuillez entrer le client!",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            })
            return
        }
        let badIndex = -1;
        selected.forEach(prod => {
            if (prod.quantite > prod.qte_total) {
                badIndex = +selected.indexOf(prod) + 1

            }
        });

        if (badIndex >= 0) {
            Swal.fire({
                title: 'Quantite insuffisante',
                text: `Vous avez saisi une quantite superieure au stock sur la ligne ${badIndex}`,
                icon: "error"
            })
            return
        }
        let facture = {
            'montant': total,
            'remise': remise,
            'montant_net': net,
            'paye': paye,
            'source': sourceSolde,
            'idmagasin': id,
            'idconteneur': +selectedConteneur.value,
            'surplus': surplus
        }
        if (total <= remise) {
            Swal.fire({
                title: "Erreur sur la remise!",
                text: "Vérifiez le montant de la remise!",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            })
            return
        }

        if (net < paye) {
            Swal.fire({
                title: "Montant trop élévé!",
                text: "Vous avez saisi un montant supérieur au montant net à payer!",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            })
            return
        }
        if (+client?.value === 978 && paye < net) {
            Swal.fire({
                title: "Facturation impossible",
                text: "Le client passager ne peut pas prendre des credit, veuillez payer tout le montant!",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            })
            return
        }
        if (sourceSolde && paye > client?.solde) {
            Swal.fire({
                title: "Solde insuffisant",
                html: `<div class="text-center">Le solde du client (<strong class="text-danger">${client?.solde?.toLocaleString()} GNF</strong>) ne peut pas couvrir le montant saisi (<strong >${paye?.toLocaleString()} GNF</strong>)</div>`,
                icon: "error",
            })
            return
        }

        setIsloadind(true)
        let status = 0
        fetch(`${defaulUrl}factures/add`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },
            body: JSON.stringify({ 'idclient': client.value, facture, selected })
        }).then(response => {
            status = response.status;

            return response.json();
        }).then(data => {
            setIsloadind(false)
            if (status === 200) {
                Swal.fire({
                    title: "Succès",
                    text: data.message,
                    icon: "success"
                });
                handleReset();
            } else {
                Swal.fire({
                    title: 'Erreur',
                    text: data.message,
                    icon: 'error'
                })
            }

        })
    }

    const handleQte = (e, prod) => {
        prod.quantite = +e.target.value
        handleTotal()

    }

    useEffect(() => {
        handleTotal();
    }, [selected])


    useEffect(() => {
        setNet(total - remise)
    }, [selected, total])

    const handleReset = () => {
        setTotal(0);
        setRemise(0);
        setNet(0);
        setPaye(0);
        setSelect([]);
        setGonext(false);
        setIsloadind(false);
        setClient({ value: '978', label: 'PASSANT' });
        setsourceSolde(false);
    }

    useEffect(() => {
        console.log(client);
    }, [client])
    return (
        <div className="Facturation container-fluid plage">
            {isLoading && <div className="loader"></div>}
            {(selected?.length > 0) && <div className="fst-itaclic h6 text-muted">{selected?.length} produit(s) sélectionné(s)</div>}
            {!gonext && (
                isloading ? <Loading /> :
                    <div className="row">
                        <h4 className="text-secondary text-center">CREATION DE FACTURE</h4>
                        <div className="row justify content-between align-items-center">
                            <div className="col-lg-6 h5 text-primary">Choix des produits</div>
                            <div className="col-lg-6 ">
                                <div className="row p-0 m-0 justify-content-end">
                                    <div className="form-group col-8">
                                        <label htmlFor="">Choisir un conteneur</label>
                                        {isloading ? <Select /> :
                                            <Select
                                                defaultInputValue={selectedConteneur?.label}
                                                options={
                                                    infoConteneur.map((cont) => (
                                                        {
                                                            value: `${cont.id}`, label: `${cont.conteneur}`
                                                        }

                                                    ))
                                                }
                                                onChange={setSelectedConteneur}
                                            />
                                        }

                                        {error && <span className="text-danger">{error}</span>}
                                    </div>
                                    <button className="btn btn-primary col-1  align-self-end text-center"
                                        onClick={() => handleshowProducts()}
                                    ><i className="bx bx-show" ></i></button>
                                </div>

                            </div>
                        </div>
                        {

                            produitsParConteneur?.length ?
                                <div className="row"  >
                                    <div className="card my-2 p-1" >
                                        <div className="card-header">
                                            <div className="card-title text-primary text-uppercase">{`${selectedConteneur.value} - ${selectedConteneur.label}`}</div>
                                        </div>
                                        <div className="card-body ">
                                            <div className="row cont">
                                                <div className="row p-2 ">
                                                    {
                                                        produitsParConteneur?.map((prod, index) => (
                                                            <div key={index} className="col-sm-3  shadow p-1 radius-15 m-0 prod">
                                                                <div className="card h-100 p-2 border-0 d-flex flex-column justify-content-center align-items-center m-0">
                                                                    <span className="text-center prod-name">{prod.produit}</span>
                                                                    <small className="fw-bold text-secondary">Qte: {prod.stock}</small>
                                                                    <strong className="prix text-center">{`${prod.pvu.toLocaleString()} GNF`}</strong>
                                                                    <button className="mt-auto btn btn-outline-success  justify-self-end" onClick={() => handSelect(prod)}>Ajouter</button>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : <div className="row p-2 justify-content-center bg-primary-subtle text-danger fs-3 mt-2">
                                    Aucun conteneur selectionné!
                                </div>
                        }
                    </div>


            )}

            {gonext && <div className="row">
                <fieldset className="border rounded bg-dark-subtle w-100">
                    <div className="row px-2 my-2 align-items-center" >
                        <label htmlFor="client" className="fs-4" >Client </label>
                        <div className="col-lg-6 ">
                            <div className="input-group">
                                {loader ? <Select className="fact-clien-input" /> :
                                    <Select className="fact-clien-input"
                                        defaultInputValue={client?.label}
                                        options={
                                            clients?.map((client) => (
                                                {
                                                    value: `${client.id}`, label: `${client.client}(${client.telephone})`,
                                                    solde: client.solde
                                                }
                                            ))
                                        }
                                        onChange={setClient}
                                    />
                                }
                                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalAddClient"><i className="bx bx-plus-circle"></i></button>
                            </div>
                        </div>
                        {client.solde > 0 && <div className="col-lg-4 d-flex gap-1 px-2  py-auto">
                            <input type="checkbox" name="" id="ck" className="form-check-input my-auto" onChange={(e) => setsourceSolde(e.target.checked)} />
                            <label className="text-primary fs-6" htmlFor="ck">Payer via solde client (Solde: {client.solde.toLocaleString()} GNF) </label>
                        </div>}
                    </div>
                </fieldset>
                <div className="table-responsive p-2">
                    <table className="table table-white table-hover table-striped">
                        <thead className="table-primary">
                            <tr>
                                <th>N°</th>
                                <th>PRODUITS</th>
                                <th>QTE STOCK</th>
                                <th>QTE</th>
                                <th>PVU</th>
                                <th>MONTANT</th>
                                <th>ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                selected?.map((prod, ind) => (
                                    <tr key={ind} className="align-middle">
                                        <td>{ind + 1}</td>
                                        <td>{prod.produit}</td>
                                        <td>{prod.qte_total}</td>
                                        <td>
                                            <input type="number" min={1} max={prod.qte_total} className="form-control" defaultValue={+prod.quantite} onChange={(e) => handleQte(e, prod)} />
                                        </td>
                                        <td>
                                            <div className="input-group">
                                                <input type="text" className="form-control"
                                                    placeholder="Entrez le prix unitaire"
                                                    defaultValue={prod.pvu.toLocaleString()}
                                                    onKeyUp={() => handleTotal()}
                                                    onChange={(e) => prod.pvu = +(e.target.value.replace(/\D/g, ""))}
                                                    onBlur={(e) => formatMoney(e)}
                                                    onFocus={(e) => onfocus(e)} />
                                                <span className="input-group-text" id="basic-addon2">GNF</span>
                                            </div>
                                        </td>
                                        <td>{(prod.pvu * prod.quantite).toLocaleString()} GNF</td>

                                        <td className="text-end ">
                                            <i className="bx bx-trash mx-auto a p-1  m-1 bg-danger-subtle 
                                            rounded m-1 text-danger fs-5"
                                                onClick={() => {
                                                    setSelect(selected.filter(p => (p !== prod)))
                                                }}></i>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className="row p-2  bg-secondary-subtle align-items-center">
                    <div className="col-lg-3 ">
                        <div className="form-group">
                            <label htmlFor="">  Total</label>
                            <div className="input-group ">
                                <input type="text" className="form-control" value={`${total?.toLocaleString()}`} placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                    onChange={(e) => {
                                        setRemise(+e.target.value.replace(/\s/g, ""));
                                        setNet(+total - (+e.target.value.replace(/\s/g, "")))
                                    }}
                                    onBlur={(e) => formatMoney(e)} disabled />
                                <span className="input-group-text" id="basic-addon2">GNF</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 ">
                        <div className="form-group ">
                            <label htmlFor=""> Sur plus</label>
                            <div className="input-group ">
                                <input type="text" className="form-control" defaultValue={remise} placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                    onChange={(e) => {
                                        setSurplus(+e.target.value.replace(/\s/g, ""));
                                        setNet(+total + (+e.target.value.replace(/\s/g, "")))
                                    }}
                                    onBlur={(e) => formatMoney(e)} onFocus={(e) => onfocus(e)} />
                                <span className="input-group-text" id="basic-addon2">GNF</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 ">
                        <div className="form-group ">
                            <label htmlFor=""> Remise</label>
                            <div className="input-group ">
                                <input type="text" className="form-control" defaultValue={remise} placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                    onChange={(e) => {
                                        setRemise(+e.target.value.replace(/\s/g, ""));
                                        setNet(+total - (+e.target.value.replace(/\s/g, "")))
                                    }}
                                    onBlur={(e) => formatMoney(e)} onFocus={(e) => onfocus(e)} />
                                <span className="input-group-text" id="basic-addon2">GNF</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 ">
                        <div className=" form-group">
                            <label htmlFor=""> Net A payer:</label>

                            <div className="input-group ">
                                <input type="text" className="form-control" value={`${net?.toLocaleString()}`} placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                    onChange={(e) => {
                                        setRemise(+e.target.value.replace(/\s/g, ""));
                                        setNet(+total - (+e.target.value.replace(/\s/g, "")))
                                    }}
                                    onBlur={(e) => formatMoney(e)} disabled />
                                <span className="input-group-text" id="basic-addon2">GNF</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 ">
                        <div className="form-group ">
                            <label htmlFor="">  Payé:</label>
                            <div className="input-group">
                                <input type="text" className="form-control" defaultValue={paye}
                                    onChange={(e) => {
                                        setPaye(+e.target.value.replace(/\s/g, ""));
                                    }}
                                    onBlur={(e) => formatMoney(e)} onFocus={(e) => onfocus(e)} />
                                <span className="input-group-text" id="basic-addon2">GNF</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            }
            <div className="row justify-content-end p-2 mt-5">
                <div className=" modal-footer">
                    {(!gonext && selected?.length > 0) &&
                        <button className="btn btn-primary m-1 btn-card " onClick={() => setGonext(true)}>
                            <i className="bx bx-cart-alt fs-1 " ></i>
                            <span className="text-warning p-1 m-auto">{selected?.length}</span>
                        </button>
                    }
                    {gonext && <button className="btn btn-danger m-1" onClick={() => setGonext(false)}> <i className="bx bx-first-page"></i> Retour
                    </button>}
                    {(selected?.length > 0 && gonext) &&
                        <button className="btn btn-success m-1" onClick={() => handleSave()} disabled={isLoading}>Enregistrer</button>}
                </div>

            </div>
            <ModalAddClient isnew={true} listClient={clients} />
        </div>
    );
}

export default Facturation;