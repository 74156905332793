import { useEffect, useState } from "react";
import { Loading } from "../components/const";
import EditePaieFacture from "../Ventes/EditePaieFacture";
const Paiements = ({ paiements, isLoading }) => {
    const [total, setTotal] = useState(0);
    const [devise, setDevise] = useState(null);
    const [selected, setSelected] = useState(null);
    useEffect(() => {
        if (paiements?.length) {
            setTotal(paiements?.reduce((previous, current) => { return previous + current.montant }, 0));
            setDevise(paiements[0]?.devise)
        }
    }, [paiements?.length])
    return (
        <div className="modal fade" id="paiement" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header bg-secondary-subtle">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Détail des paiements  </h1>
                        <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {
                            isLoading ? <Loading /> : paiements?.length ?
                                <div className="table-responsive">
                                    <table className="table-table-hover table bordered table striped">
                                        <thead className="table-primary">
                                            <tr>
                                                <th>N°</th>
                                                <th>MONTANT</th>
                                                <th>DESCRIPTION</th>
                                                <th>SOURCE</th>
                                                <th>DATE</th>
                                                {/* <th>ACTION</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                paiements.map((paie, ind) => (
                                                    <tr key={ind}>
                                                        <td>{ind + 1}</td>
                                                        <td>{paie?.montant?.toLocaleString()}{devise ?? ''}</td>
                                                        <td>{paie?.description}</td>
                                                        <td>{paie?.source}</td>
                                                        <td>{paie?.created_at}</td>
                                                        {/* <td className="text-end">
                                                            <button className="btn btn-primary" data-bs-toggle="modal"
                                                                data-bs-target="#editePaie" onClick={()=>setSelected(paie)}>
                                                                <i className="bx bx-edit"></i>
                                                            </button>
                                                        </td> */}
                                                    </tr>
                                                ))
                                            }
                                            <tr className="bg-secondary-subtle">
                                                <td className="fs-5 fw-bold text-center">Total</td>
                                                <td className="fw-bold">{total.toLocaleString()}{devise ?? ''}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> : <div className="row p-2 bg-danger-subtle text-danger justify-content-center">Aucun paiement effectué pour cette facture!</div>
                        }
                    </div>
                </div>
            </div>

            <EditePaieFacture paie={selected} />
        </div>
    );
}

export default Paiements;